var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/components/Button/index.tsx
import { useState, useEffect, useRef } from "react";
import { SystemRestart } from "iconoir-react";
import { jsx, jsxs } from "react/jsx-runtime";
var Button = (_a) => {
  var _b = _a, {
    variant = "primary",
    size = "large",
    type = "button",
    iconLeft,
    iconRight,
    onClick,
    loading,
    disabled,
    fullWidth,
    children
  } = _b, rest = __objRest(_b, [
    "variant",
    "size",
    "type",
    "iconLeft",
    "iconRight",
    "onClick",
    "loading",
    "disabled",
    "fullWidth",
    "children"
  ]);
  const [widthOfBtn, setWidthOfBtn] = useState("");
  const buttonRef = useRef(null);
  const hasOneIcon = (iconLeft && !iconRight || iconRight && !iconLeft) && !children;
  useEffect(() => {
    var _a2;
    setWidthOfBtn(`${(_a2 = buttonRef == null ? void 0 : buttonRef.current) == null ? void 0 : _a2.getBoundingClientRect().width}px`);
  });
  useEffect(() => {
    if (buttonRef.current && loading) {
      buttonRef.current.style.width = widthOfBtn;
    } else if (buttonRef.current) {
      buttonRef.current.style.width = "";
    }
  }, [loading]);
  const backgroundColor = {
    primary: "bg-buttonPrimaryBackground hover:bg-buttonPrimaryBackgroundhover focus:bg-buttonPrimaryBackgroundfocus active:bg-buttonPrimaryBackgroundactive",
    secondary: "bg-brandWhite hover:bg-buttonSecondaryBackgroundhover focus:bg-buttonSecondaryBackgroundfocus active:bg-buttonSecondaryBackgroundactive",
    tertiary: "bg-buttonTertiaryBackground hover:bg-buttonTertiaryBackgroundhover focus:bg-buttonTertiaryBackgroundfocus active:bg-buttonTertiaryBackgroundactive",
    link: "bg-transparent",
    ghost: "bg-brandWhite",
    success: "bg-uiSuccess500 hover:bg-uiSuccess600 focus:bg-uiSuccess600 active:bg-uiSuccess500",
    block: "bg-brandWhite",
    default: "bg-buttonPrimaryBackground"
  }[variant || "default"];
  const color = {
    primary: "text-buttonPrimaryText",
    secondary: "text-buttonSecondaryText",
    tertiary: "text-buttonTertiaryText",
    link: "text-buttonTextColor hover:text-buttonTextColorhover focus:text-buttonTextColorfocus active:text-buttonTextColoractive",
    ghost: "text-buttonTextColor hover:text-buttonTextColorhover focus:text-buttonTextColorfocus active:text-buttonTextColoractive",
    success: "text-buttonPrimaryText",
    block: "text-textcolorBlack",
    default: "text-buttonPrimaryText"
  }[variant || "default"];
  const border = {
    primary: "border-3 border-solid border-transparent focus:border-buttonPrimaryFocusring active:border-buttonPrimaryFocusring",
    secondary: "border-3 border-solid border-buttonSecondaryBackground hover:border-transparent focus:border-buttonSecondaryFocusring active:border-buttonSecondaryFocusring",
    tertiary: "border-3 border-solid border-transparent focus:border-buttonTertiaryFocusring active:border-buttonTertiaryFocusring",
    link: "border-0",
    ghost: "border-0",
    success: "border-3 border-solid border-transparent focus:border-buttonPrimaryFocusring active:border-buttonPrimaryFocusring",
    block: "border-3 border-solid border-borderDefault",
    default: "border-0"
  }[variant || "default"];
  const dropShadow = {
    primary: "hover:shadow-lg",
    secondary: "hover:shadow-lg",
    tertiary: "hover:shadow-lg",
    link: "",
    ghost: "",
    success: "hover:shadow-lg",
    block: "hover:shadow-md focus:shadow-lg",
    default: "hover:shadow-lg"
  }[variant || "default"];
  const height = {
    large: "h-12",
    medium: "h-10",
    small: "h-9",
    default: "h-12"
  }[size || "default"];
  const width = {
    large: "w-12",
    medium: "w-10",
    small: "w-9",
    default: "w-12"
  }[size || "default"];
  const paddingVertical = {
    large: "px-5",
    medium: "px-5",
    small: "px-3.5",
    default: "px-5"
  }[size || "default"];
  const fontSize = {
    large: "text-base",
    medium: "text-xs",
    small: "text-xs",
    default: "text-base"
  }[size || "default"];
  return /* @__PURE__ */ jsx(
    "button",
    __spreadProps(__spreadValues({
      type,
      className: `${backgroundColor} ${color} ${border} ${dropShadow} ${variant !== "link" && hasOneIcon ? width : ""} ${variant !== "link" ? height : ""} ${variant !== "link" && !hasOneIcon ? paddingVertical : ""} ${fontSize} ${fullWidth ? "w-full" : ""} ${variant === "link" ? "p-0" : ""} ${variant === "block" ? "rounded-none" : "rounded-full"} leading-5 font-weight-primary-bold font-primary cursor-pointer disabled:opacity-20 flex flex-wrap content-center justify-center whitespace-nowrap`,
      onClick,
      disabled,
      ref: buttonRef
    }, rest), {
      children: loading ? /* @__PURE__ */ jsx(
        "div",
        {
          className: `flex flex-nowrap content-center justify-center animate-spin-1.5`,
          children: /* @__PURE__ */ jsx(SystemRestart, { strokeWidth: 2, height: 21, width: 21 })
        }
      ) : /* @__PURE__ */ jsxs("div", { className: `flex flex-nowrap content-center justify-center`, children: [
        iconLeft && /* @__PURE__ */ jsx(
          "div",
          {
            className: `${hasOneIcon ? "mr-0" : "mr-2"} flex flex-nowrap content-center justify-center`,
            children: iconLeft
          }
        ),
        children,
        iconRight && /* @__PURE__ */ jsx(
          "div",
          {
            className: `${hasOneIcon ? "ml-0" : "ml-2"} flex flex-nowrap content-center justify-center`,
            children: iconRight
          }
        )
      ] })
    })
  );
};

// src/components/Icon/index.tsx
import { useEffect as useEffect2, useState as useState2 } from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var Icon = (_a) => {
  var _b = _a, {
    name,
    size = "sm",
    fill
  } = _b, rest = __objRest(_b, [
    "name",
    "size",
    "fill"
  ]);
  const [IconComponent, setIconComponent] = useState2(null);
  useEffect2(() => {
    getIconComponent();
  }, [name]);
  const getIconComponent = () => __async(void 0, null, function* () {
    const component = yield import("iconoir-react").then((module) => module[name]);
    setIconComponent(component);
  });
  const sizing = {
    "2xs": "w-3.5 h-3.5",
    xs: "w-4 h-4",
    sm: "w-5 h-5",
    md: "w-7 h-7",
    lg: "w-9 h-9",
    xl: "w-12 h-12",
    default: ""
  }[size || "default"];
  if (!IconComponent) {
    return null;
  }
  ;
  return /* @__PURE__ */ jsx2(
    IconComponent,
    __spreadValues({
      className: `${sizing} icon`,
      fill: fill ? "currentColor" : "none"
    }, rest)
  );
};

// src/components/TitleAndSubtitle/index.tsx
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var TitleAndSubtitle = (_a) => {
  var _b = _a, {
    title,
    subtitle
  } = _b, rest = __objRest(_b, [
    "title",
    "subtitle"
  ]);
  return /* @__PURE__ */ jsxs2("div", __spreadProps(__spreadValues({}, rest), { children: [
    title && /* @__PURE__ */ jsx3("div", { className: `font-bold text-textcolorBlack font-secondary line-clamp-2 leading-5`, children: title }),
    subtitle && /* @__PURE__ */ jsx3("div", { className: `text-textcolorGrey font-primary line-clamp-1 leading-5`, children: subtitle })
  ] }));
};

// src/components/ProductImage/index.tsx
import { useState as useState3, useEffect as useEffect3 } from "react";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var ProductImage = (_a) => {
  var _b = _a, { product, badge, maxHeight } = _b, rest = __objRest(_b, ["product", "badge", "maxHeight"]);
  const [opacity, setOpacity] = useState3("opacity-0");
  const [source, setSource] = useState3(product.image);
  const isHardcover = product.type === "Hardcover" || product.tbaproducttype === "Hardcover";
  const isPaperback = product.type === "Paperback" || product.tbaproducttype === "Paperback";
  const isDwarsligger = product.type === "Dwarsligger" || product.tbaproducttype === "Dwarsligger";
  const [showBookStyling, setShowBookStyling] = useState3(isHardcover || isPaperback || isDwarsligger);
  const altText = product.name;
  const fallbackImage = product.fallbackImage;
  const bookStylingBefore = `before:content-[''] before:w-1.5 before:h-full before:absolute before:rounded-l-sm before:z-10 before:block before:bg-gradient-to-l before:from-transparent before:to-black/30 before:shadow-[1px_0_rgba(0,0,0,0.1)]`;
  const bookStylingAfter = `after:content-[''] after:block after:absolute after:right-full after:w-3.5 after:h-[95%] after:top-[2.5%] after:bg-gradient-to-r after:from-transparent after:to-black/25 after:origin-right after:rotate-y-15`;
  useEffect3(() => {
    setSource(product.image);
  }, [product]);
  const onLoadEvent = () => {
    setOpacity("opacity-100");
  };
  const onErrorEvent = () => {
    setSource(fallbackImage);
    setShowBookStyling(false);
  };
  return /* @__PURE__ */ jsxs3(
    "div",
    __spreadProps(__spreadValues({
      className: `relative z-0 ${!!showBookStyling ? bookStylingBefore : ""} ${!!showBookStyling ? bookStylingAfter : ""}`,
      style: { maxHeight: maxHeight && `${maxHeight}px` }
    }, rest), {
      children: [
        badge && /* @__PURE__ */ jsx4("div", { className: "absolute z-20 -top-3.5 -left-3.5", children: badge }),
        /* @__PURE__ */ jsx4(
          "img",
          {
            src: source,
            alt: altText,
            className: `object-contain z-0 relative ${opacity} ${showBookStyling ? "rounded-l-sm" : ""}`,
            style: { maxHeight: maxHeight && `${maxHeight}px` },
            loading: "lazy",
            onLoad: onLoadEvent,
            onError: onErrorEvent
          }
        )
      ]
    })
  );
};

// src/components/Badge/index.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var Badge = (_a) => {
  var _b = _a, {
    variant = "regular",
    theme = "cta",
    size = "small",
    outlined = false,
    number,
    icon,
    text
  } = _b, rest = __objRest(_b, [
    "variant",
    "theme",
    "size",
    "outlined",
    "number",
    "icon",
    "text"
  ]);
  const surface = variant === "tag" ? "" : {
    tiny: "h-4 min-w-[1rem]",
    xsmall: "h-5 min-w-[1.25rem]",
    small: "h-9 min-w-[2.25rem]",
    medium: "h-12 min-w-[3rem]"
  }[size || "small"];
  const fontSize = {
    tiny: "text-xs",
    xsmall: "text-sm",
    small: "text-base",
    medium: "text-2xl"
  }[size || "small"];
  const colors = !outlined ? {
    info: "bg-badgeInfoBackground text-badgeInfoText border-brandWhite",
    success: "bg-badgeSuccessBackground text-badgeSuccessText border-brandWhite",
    danger: "bg-badgeDangerBackground text-badgeDangerText border-brandWhite",
    error: "bg-badgeErrorBackground text-badgeErrorText border-brandWhite",
    cta: "bg-badgeCtaBackground text-badgeCtaText border-brandWhite",
    neutral: "bg-badgeNeutralBackground text-badgeNeutralText border-brandWhite"
  }[theme || "cta"] : {
    info: "bg-brandWhite text-badgeInfoBackground border-badgeInfoBackground",
    success: "bg-brandWhite text-badgeSuccessBackground border-badgeSuccessBackground",
    danger: "bg-brandWhite text-badgeDangerBackground border-badgeDangerBackground",
    error: "bg-brandWhite text-badgeErrorBackground border-badgeErrorBackground",
    cta: "bg-brandWhite text-badgeCtaBackground border-badgeCtaBackground",
    neutral: "bg-brandWhite text-badgeNeutralBackground border-badgeNeutralBackground"
  }[theme || "cta"];
  const radius = variant === "tag" ? "" : "rounded-full";
  const padding = variant === "tag" ? "p-2" : "px-1";
  const cNumber = number < 1e3 ? number : "999+";
  const cText = /* @__PURE__ */ jsx5("div", { className: "max-w-[12ch] truncate tracking-widest", children: text });
  return /* @__PURE__ */ jsx5(
    "div",
    __spreadProps(__spreadValues({
      className: `${fontSize} ${surface} ${padding} ${colors} ${radius} inline-flex justify-center items-center font-medium whitespace-nowrap border border-solid`
    }, rest), {
      children: variant === "tag" ? cText : icon || cNumber
    })
  );
};

// src/components/Link/index.tsx
import { NavLink } from "react-router-dom";

// src/utils/index.ts
function isHtmlLink(url = "") {
  const isExternalLink = url.includes("http");
  const isEmailLink = url.includes("mailto:");
  const isPhoneLink = url.includes("tel:");
  return isExternalLink || isEmailLink || isPhoneLink;
}
var isBrowser = typeof window !== "undefined";
function clipboardFirefox(id) {
  const input = document.getElementById(id);
  input.focus();
  input.select();
  document.execCommand("copy");
}
function copyToClipboard(text, id) {
  return __async(this, null, function* () {
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      clipboardFirefox(id);
    } else {
      const type = "text/plain";
      const blob = new Blob([text], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      yield navigator.clipboard.write(data).then(() => true);
    }
  });
}
function capitalize(string) {
  if (!string) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

// src/components/Link/index.tsx
import { jsx as jsx6 } from "react/jsx-runtime";
var Link = (_a) => {
  var _b = _a, {
    href,
    className,
    children
  } = _b, rest = __objRest(_b, [
    "href",
    "className",
    "children"
  ]);
  let link = href || "/";
  if (!!isBrowser && href.includes("://")) {
    const { host, pathname } = new URL(link);
    if (host === (location == null ? void 0 : location.host)) {
      link = pathname;
    }
  }
  return !isHtmlLink(link) ? /* @__PURE__ */ jsx6(
    NavLink,
    __spreadProps(__spreadValues({
      to: link,
      exact: true,
      className
    }, rest), {
      children
    })
  ) : /* @__PURE__ */ jsx6("a", __spreadProps(__spreadValues({ href: link, className }, rest), { children }));
};

// src/components/PromoNotification/index.tsx
import { jsx as jsx7, jsxs as jsxs4 } from "react/jsx-runtime";
var PromoNotification = (_a) => {
  var _b = _a, {
    size = "small",
    link,
    title,
    subtitle
  } = _b, rest = __objRest(_b, [
    "size",
    "link",
    "title",
    "subtitle"
  ]);
  const padding = size === "medium" ? "p-4" : "p-2";
  const fontSize = size === "medium" ? "text-default" : "text-sm";
  const margin = size === "medium" ? "mb-4" : "mb-2";
  const classNames = `${padding} ${fontSize} ${margin} bg-badgeBlockBackground flex w-full sm:w-fit border-0 border-l-4 border-solid border-badgeBlockHighlight no-underline`;
  const renderContent = () => {
    return /* @__PURE__ */ jsxs4("div", { className: "flex w-full sm:w-fit justify-between sm:justify-start", children: [
      /* @__PURE__ */ jsxs4("div", { children: [
        /* @__PURE__ */ jsx7("div", { className: "font-weight-primary-bold text-badgeBlockText font-primary text-base", children: title }),
        subtitle && /* @__PURE__ */ jsx7("div", { className: "text-xs uppercase tracking-widest text-badgeBlockText", children: subtitle })
      ] }),
      link && /* @__PURE__ */ jsx7("div", { className: "flex items-center ml-2", children: /* @__PURE__ */ jsx7(Icon, { name: "NavArrowRight", size: "sm" }) })
    ] });
  };
  if (!title) {
    return null;
  }
  return link ? /* @__PURE__ */ jsx7(Link, __spreadProps(__spreadValues({ href: link, className: classNames }, rest), { children: renderContent() })) : /* @__PURE__ */ jsx7("div", __spreadProps(__spreadValues({ className: classNames }, rest), { children: renderContent() }));
};

// src/components/Rating/index.tsx
import { useState as useState4 } from "react";
import { jsx as jsx8 } from "react/jsx-runtime";
var Rating = (_a) => {
  var _b = _a, {
    size = "small",
    rating,
    onClick,
    hoverable = false
  } = _b, rest = __objRest(_b, [
    "size",
    "rating",
    "onClick",
    "hoverable"
  ]);
  const [previewRating, setPreviewRating] = useState4(null);
  const [clickedRating, setClickedRating] = useState4(null);
  const hoverStar = (exit) => {
    return (e) => {
      let hoverRating = null;
      if (!hoverable || !onClick) {
        return null;
      } else if (!!clickedRating) {
        hoverRating = clickedRating;
      } else if (exit) {
        hoverRating = null;
      } else {
        hoverRating = e.currentTarget.getAttribute("data-rating");
      }
      setPreviewRating(hoverRating);
    };
  };
  const ratedClass = (i) => {
    if (!!previewRating) {
      return i <= previewRating ? "text-iconRatingActive" : "text-iconRatingInactive";
    }
    return i <= rating ? "text-iconRatingActive" : "text-iconRatingInactive";
  };
  const handleClick = (rating2) => {
    if (!hoverable) {
      return null;
    }
    setClickedRating(rating2);
    onClick(rating2);
  };
  const stars = [1, 2, 3, 4, 5].map((i) => {
    return /* @__PURE__ */ jsx8(
      "span",
      {
        "data-rating": i,
        className: `${ratedClass(i)} flex`,
        onMouseEnter: hoverStar(false),
        onMouseLeave: hoverStar(true),
        onClick: () => handleClick(i),
        children: /* @__PURE__ */ jsx8(Icon, { name: "Star", size: size === "large" ? "md" : "xs", fill: true })
      },
      `stars-${i}`
    );
  });
  return /* @__PURE__ */ jsx8("div", __spreadProps(__spreadValues({ className: "flex" }, rest), { children: stars }));
};

// src/components/Excerpt/index.tsx
import { useState as useState5, useEffect as useEffect4, useRef as useRef2 } from "react";
import { jsx as jsx9, jsxs as jsxs5 } from "react/jsx-runtime";
var Excerpt = (_a) => {
  var _b = _a, {
    text,
    showFormatting = false,
    columnCount = 1,
    maxLines = 0,
    maxLinesSm = 0,
    maxLinesMd = 0,
    maxLinesLg = 0,
    maxLinesXl = 0,
    maxLines2Xl = 0,
    label1,
    label2,
    href
  } = _b, rest = __objRest(_b, [
    "text",
    "showFormatting",
    "columnCount",
    "maxLines",
    "maxLinesSm",
    "maxLinesMd",
    "maxLinesLg",
    "maxLinesXl",
    "maxLines2Xl",
    "label1",
    "label2",
    "href"
  ]);
  if (!text) {
    return null;
  }
  const excerptRef = useRef2(null);
  const [fullHeight, setFullHeight] = useState5(false);
  const [showLabel, setShowLabel] = useState5(false);
  useEffect4(() => {
    const { clientHeight, scrollHeight } = excerptRef == null ? void 0 : excerptRef.current;
    setShowLabel(scrollHeight > clientHeight);
  }, []);
  const lineClampXs = fullHeight ? "" : {
    0: "",
    1: "line-clamp-1",
    2: "line-clamp-2",
    3: "line-clamp-3",
    4: "line-clamp-4",
    5: "line-clamp-5",
    6: "line-clamp-6",
    7: "line-clamp-7",
    8: "line-clamp-8"
  }[maxLines || 0];
  const lineClampSm = fullHeight ? "" : {
    0: "",
    1: "sm:line-clamp-1",
    2: "sm:line-clamp-2",
    3: "sm:line-clamp-3",
    4: "sm:line-clamp-4",
    5: "sm:line-clamp-5",
    6: "sm:line-clamp-6",
    7: "sm:line-clamp-7",
    8: "sm:line-clamp-8"
  }[maxLinesSm || 0];
  const lineClampMd = fullHeight ? "" : {
    0: "",
    1: "md:line-clamp-1",
    2: "md:line-clamp-2",
    3: "md:line-clamp-3",
    4: "md:line-clamp-4",
    5: "md:line-clamp-5",
    6: "md:line-clamp-6",
    7: "md:line-clamp-7",
    8: "md:line-clamp-8"
  }[maxLinesMd || 0];
  const lineClampLg = fullHeight ? "" : {
    0: "",
    1: "lg:line-clamp-1",
    2: "lg:line-clamp-2",
    3: "lg:line-clamp-3",
    4: "lg:line-clamp-4",
    5: "lg:line-clamp-5",
    6: "lg:line-clamp-6",
    7: "lg:line-clamp-7",
    8: "lg:line-clamp-8"
  }[maxLinesLg || 0];
  const lineClampXl = fullHeight ? "" : {
    0: "",
    1: "xl:line-clamp-1",
    2: "xl:line-clamp-2",
    3: "xl:line-clamp-3",
    4: "xl:line-clamp-4",
    5: "xl:line-clamp-5",
    6: "xl:line-clamp-6",
    7: "xl:line-clamp-7",
    8: "xl:line-clamp-8"
  }[maxLinesXl || 0];
  const lineClamp2Xl = fullHeight ? "" : {
    0: "",
    1: "2xl:line-clamp-1",
    2: "2xl:line-clamp-2",
    3: "2xl:line-clamp-3",
    4: "2xl:line-clamp-4",
    5: "2xl:line-clamp-5",
    6: "2xl:line-clamp-6",
    7: "2xl:line-clamp-7",
    8: "2xl:line-clamp-8"
  }[maxLines2Xl || 0];
  let columns = "";
  if (columnCount) {
    switch (true) {
      case !!maxLines2Xl:
        columns = "";
        break;
      case !!maxLinesXl:
        columns = {
          1: "",
          2: "2xl:columns-2 gap-7 2xl:line-clamp-none 2xl:block",
          3: "2xl:columns-3 gap-7 2xl:line-clamp-none 2xl:block",
          4: "2xl:columns-4 gap-7 2xl:line-clamp-none 2xl:block"
        }[columnCount || 1];
        break;
      case !!maxLinesLg:
        columns = {
          1: "",
          2: "xl:columns-2 gap-7 xl:line-clamp-none xl:block",
          3: "xl:columns-3 gap-7 xl:line-clamp-none xl:block",
          4: "xl:columns-4 gap-7 xl:line-clamp-none xl:block"
        }[columnCount || 1];
        break;
      case !!maxLinesMd:
        columns = {
          1: "",
          2: "lg:columns-2 gap-7 lg:line-clamp-none lg:block",
          3: "lg:columns-3 gap-7 lg:line-clamp-none lg:block",
          4: "lg:columns-4 gap-7 lg:line-clamp-none lg:block"
        }[columnCount || 1];
        break;
      case !!maxLinesSm:
        columns = {
          1: "",
          2: "md:columns-2 gap-7 md:line-clamp-none md:block",
          3: "md:columns-3 gap-7 md:line-clamp-none md:block",
          4: "md:columns-4 gap-7 md:line-clamp-none md:block"
        }[columnCount || 1];
        break;
      case !!maxLines:
        columns = {
          1: "",
          2: "sm:columns-2 gap-7 sm:line-clamp-none sm:block",
          3: "sm:columns-3 gap-7 sm:line-clamp-none sm:block",
          4: "sm:columns-4 gap-7 sm:line-clamp-none sm:block"
        }[columnCount || 1];
        break;
      default:
        columns = {
          1: "",
          2: "columns-2 gap-7",
          3: "columns-3 gap-7",
          4: "columns-4 gap-7"
        }[columnCount || 1];
    }
  }
  const fontSize = columnCount > 1 ? "text-sm" : "text-base";
  const toggleHeight = (e) => {
    e.preventDefault();
    setFullHeight(!fullHeight);
  };
  const renderLabel = () => {
    if (!showLabel) {
      return null;
    }
    if (showFormatting) {
      if (!href) {
        return null;
      }
      return /* @__PURE__ */ jsxs5("div", { className: "absolute right-0 bottom-0 pl-20 bg-gradient-to-r from-transparent via-white to-white", children: [
        "...",
        " ",
        /* @__PURE__ */ jsx9(Link, { href, className: "underline text-linkPrimary", children: label1 })
      ] });
    } else {
      return /* @__PURE__ */ jsxs5(
        "a",
        {
          href: "#",
          className: "flex items-center text-linkPrimary",
          onClick: toggleHeight,
          children: [
            fullHeight ? label2 : label1,
            " ",
            /* @__PURE__ */ jsx9(Icon, { name: fullHeight ? "NavArrowUp" : "NavArrowDown", size: "sm" })
          ]
        }
      );
    }
  };
  return /* @__PURE__ */ jsxs5("div", __spreadProps(__spreadValues({}, rest), { className: "relative", children: [
    /* @__PURE__ */ jsx9(
      "div",
      {
        className: `${lineClampXs} ${lineClampSm} ${lineClampMd} ${lineClampLg} ${lineClampXl} ${lineClamp2Xl} ${columns} ${fontSize}`,
        ref: excerptRef,
        dangerouslySetInnerHTML: { __html: text }
      }
    ),
    renderLabel()
  ] }));
};

// src/components/Annotation/index.tsx
import { jsx as jsx10 } from "react/jsx-runtime";
var Annotation = (_a) => {
  var _b = _a, {
    annotation
  } = _b, rest = __objRest(_b, [
    "annotation"
  ]);
  if (!annotation)
    return null;
  return /* @__PURE__ */ jsx10("div", __spreadProps(__spreadValues({}, rest), { className: "font-primary text-2xl", dangerouslySetInnerHTML: { __html: annotation } }));
};

// src/components/CopyText/index.tsx
import { useState as useState6, useEffect as useEffect5 } from "react";
import { jsx as jsx11, jsxs as jsxs6 } from "react/jsx-runtime";
var CopyText = (_a) => {
  var _b = _a, { textValue } = _b, rest = __objRest(_b, ["textValue"]);
  if (!textValue)
    return null;
  const [copied, setCopied] = useState6(false);
  const id = Date.now();
  const copy = () => {
    setCopied(true);
    copyToClipboard(textValue, id);
  };
  useEffect5(() => {
    const timeout = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 5e3);
    return () => clearTimeout(timeout);
  }, [copied]);
  const copiedIconCopy = copied ? "opacity-0 transition-opacity duration-300 ease-in" : "opacity-1";
  const copiedIconCheck = copied ? "opacity-1" : "opacity-0";
  return /* @__PURE__ */ jsxs6(
    "div",
    __spreadProps(__spreadValues({}, rest), {
      onClick: copy,
      className: "font-primary flex text-sm text-textcolorBlack cursor-pointer center hover:text-linkPrimary hover:underline",
      children: [
        /* @__PURE__ */ jsx11(
          "input",
          {
            className: "opacity-0 absolute z-0",
            id,
            value: textValue || "",
            readOnly: true
          }
        ),
        /* @__PURE__ */ jsx11("span", { className: "relative", children: textValue }),
        /* @__PURE__ */ jsxs6("div", { className: "relative w-3.5", children: [
          /* @__PURE__ */ jsx11("div", { className: `absolute ${copiedIconCopy}`, children: /* @__PURE__ */ jsx11(Icon, { name: "Copy", size: "sm" }) }),
          /* @__PURE__ */ jsx11("div", { className: `absolute ${copiedIconCheck}`, children: /* @__PURE__ */ jsx11(Icon, { name: "Check", size: "sm" }) })
        ] })
      ]
    })
  );
};

// src/components/Tooltip/index.tsx
import { useState as useState7, useRef as useRef3, useEffect as useEffect6 } from "react";
import {
  useFloating,
  autoUpdate,
  useHover,
  useFocus,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  offset,
  shift,
  FloatingArrow,
  arrow
} from "@floating-ui/react";
import { jsx as jsx12, jsxs as jsxs7 } from "react/jsx-runtime";
var Tooltip = (_a) => {
  var _b = _a, {
    position = "top",
    type = "default",
    content,
    children,
    trigger = "click",
    openTooltip = false,
    fullWidth = false
  } = _b, rest = __objRest(_b, [
    "position",
    "type",
    "content",
    "children",
    "trigger",
    "openTooltip",
    "fullWidth"
  ]);
  const [isOpen, setIsOpen] = useState7(false);
  useEffect6(() => {
    setIsOpen(openTooltip);
  }, [openTooltip]);
  const arrowRef = useRef3(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    middleware: [
      offset(7),
      shift({ padding: 7 }),
      arrow({ element: arrowRef })
    ],
    whileElementsMounted: autoUpdate
  });
  const hover = useHover(context, {
    enabled: trigger === "hover",
    move: false
  });
  const focus = useFocus(context, { enabled: trigger !== "function" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });
  const click = useClick(context, { enabled: trigger === "click" });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    click
  ]);
  const backgroundColor = {
    default: "bg-brandWhite",
    warning: "bg-uiErrorStrong",
    danger: "bg-uiDangerStrong",
    success: "bg-uiSuccess600",
    info: "bg-uiInfoStrong"
  }[type || "default"];
  const arrowColor = {
    default: "fill-brandWhite",
    warning: "fill-uiErrorStrong",
    danger: "fill-uiDangerStrong",
    success: "fill-uiSuccess600",
    info: "fill-uiInfoStrong"
  }[type || "default"];
  const width = fullWidth ? "w-full" : "max-w-full";
  const textColor = type === "default" ? "text-textcolorGrey" : "text-textcolorWhite";
  const referenceBorder = type === "default" ? "border border-solid border-borderDefault shadow" : "";
  const arrowBorder = type === "default" ? "[&>path:first-of-type]:stroke-borderDefault [&>path:last-of-type]:stroke-brandWhite" : "";
  const renderContent = () => {
    return /* @__PURE__ */ jsxs7(
      "div",
      __spreadProps(__spreadValues({
        className: `${backgroundColor} ${textColor} ${referenceBorder} p-2 text-sm max-w-xs`,
        ref: refs.setFloating,
        style: floatingStyles
      }, getFloatingProps()), {
        children: [
          /* @__PURE__ */ jsx12(
            FloatingArrow,
            {
              ref: arrowRef,
              context,
              strokeWidth: type === "default" ? 2 : 0,
              className: `${arrowColor} ${arrowBorder}`
            }
          ),
          content
        ]
      })
    );
  };
  return /* @__PURE__ */ jsxs7("div", __spreadProps(__spreadValues({}, rest), { className: `flex ${width}`, children: [
    /* @__PURE__ */ jsx12(
      "div",
      __spreadProps(__spreadValues({
        className: `flex ${width} ${trigger === "click" ? "cursor-pointer" : ""}`,
        ref: refs.setReference
      }, getReferenceProps()), {
        children
      })
    ),
    isOpen && renderContent()
  ] }));
};

// src/components/Metabar/index.tsx
import { jsx as jsx13 } from "react/jsx-runtime";
var Metabar = (_a) => {
  var _b = _a, { children } = _b, rest = __objRest(_b, ["children"]);
  if (!children)
    return null;
  return /* @__PURE__ */ jsx13(
    "div",
    __spreadProps(__spreadValues({
      className: "flex flex-wrap items-center [&:not(:last-child)]:child:pr-3 child:mr-3 child:border-0 [&:not(:last-child)]:child:border-r child:border-solid child:border-borderDefault"
    }, rest), {
      children
    })
  );
};

// src/components/QuantityInput/index.tsx
import { useState as useState8 } from "react";
import { jsx as jsx14, jsxs as jsxs8 } from "react/jsx-runtime";
var QuantityInput = (_a) => {
  var _b = _a, {
    value = 1,
    maxQuantity = null,
    minQuantity = 1,
    changeQuantity,
    openOverlay = false,
    isModal = false,
    size = "large",
    type = "primary",
    errorMessage = "",
    fullWidth = false
  } = _b, rest = __objRest(_b, [
    "value",
    "maxQuantity",
    "minQuantity",
    "changeQuantity",
    "openOverlay",
    "isModal",
    "size",
    "type",
    "errorMessage",
    "fullWidth"
  ]);
  const [quantity, setQuantity] = useState8(value);
  const [showErrorMessage, setShowErrorMessage] = useState8(false);
  const quantityIncrement = () => {
    if (openOverlay) {
      return null;
    }
    let newValue;
    if (maxQuantity !== null && maxQuantity <= quantity) {
      newValue = maxQuantity;
      setShowErrorMessage(true);
    } else {
      newValue = quantity + 1;
    }
    setQuantity(newValue);
    changeQuantity(newValue);
  };
  const quantityDecrement = () => {
    if (quantity <= minQuantity || openOverlay) {
      return null;
    }
    const newValue = quantity - 1;
    setQuantity(newValue);
    changeQuantity(newValue);
    if (maxQuantity !== null && maxQuantity >= newValue) {
      setShowErrorMessage(false);
    }
  };
  const updateQuantity = (e) => {
    const { value: value2 } = e.target;
    if (isModal) {
      verifyQuantity(e);
    }
    setQuantity(value2);
    changeQuantity(value2);
  };
  const verifyQuantity = (e) => {
    if (openOverlay) {
      return null;
    }
    const { value: value2 } = e.target;
    let newValue;
    let hasError = false;
    if (quantity < 2) {
      newValue = 1;
    } else if (maxQuantity !== null && value2 > maxQuantity) {
      newValue = isModal ? value2 : maxQuantity;
      hasError = true;
    } else {
      newValue = value2;
    }
    setQuantity(Number(newValue));
    changeQuantity(Number(newValue));
    setShowErrorMessage(hasError);
  };
  const verifyInput = (e) => {
    const notAcceptedCharacters = [
      "e",
      "E",
      "+",
      "-",
      ",",
      ".",
      "PageDown",
      "PageUp"
    ];
    if (notAcceptedCharacters.includes(e.key) || e.key === "0" && e.target.value === "") {
      e.preventDefault();
    }
  };
  const height = {
    large: "h-12",
    medium: "h-10",
    small: "h-9"
  }[size || "large"];
  const fontSize = {
    large: "text-base",
    medium: "text-xs",
    small: "text-xs",
    default: "text-base"
  }[size || "default"];
  const borderColor = type === "ghost" ? "border-buttonSecondaryBackground" : "border-buttonPrimaryBackground";
  return /* @__PURE__ */ jsx14("div", __spreadProps(__spreadValues({ className: fullWidth ? "w-full" : "" }, rest), { children: /* @__PURE__ */ jsx14(
    Tooltip,
    {
      content: errorMessage,
      trigger: "function",
      position: "top",
      type: "default",
      openTooltip: showErrorMessage,
      fullWidth,
      children: /* @__PURE__ */ jsxs8(
        "div",
        {
          className: `flex items-center justify-center rounded-full  px-2 bg-brandWhite border-3 border-solid ${borderColor} ${height} ${fullWidth ? "w-full" : ""}`,
          children: [
            /* @__PURE__ */ jsx14(
              "button",
              {
                onClick: quantityDecrement,
                className: "flex items-center border-0 p-0 bg-brandWhite text-buttonSecondaryText cursor-pointer",
                "data-test-id": "quantityDecrement",
                children: /* @__PURE__ */ jsx14(Icon, { name: "MinusCircle", size: "sm" })
              }
            ),
            /* @__PURE__ */ jsx14(
              "input",
              {
                className: `p-0 border-0 w-7 text-center font-primary text-buttonSecondaryText font-weight-primary-bold outline-0 [appearance:textfield]  [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${fontSize}`,
                type: "number",
                onKeyDown: verifyInput,
                onChange: updateQuantity,
                onBlur: verifyQuantity,
                value: openOverlay ? value : quantity,
                min: 1,
                step: 1,
                readOnly: openOverlay,
                "data-test-id": "quantityInput"
              }
            ),
            /* @__PURE__ */ jsx14(
              "button",
              {
                onClick: quantityIncrement,
                className: "flex items-center border-0 p-0 bg-brandWhite text-buttonSecondaryText cursor-pointer",
                "data-test-id": "quantityIncrement",
                children: /* @__PURE__ */ jsx14(
                  Icon,
                  {
                    name: !maxQuantity || quantity < maxQuantity ? "PlusCircle" : "Prohibition",
                    size: "sm"
                  }
                )
              }
            )
          ]
        }
      )
    }
  ) }));
};

// src/components/Switch/index.tsx
import { jsx as jsx15, jsxs as jsxs9 } from "react/jsx-runtime";
var Switch = (_a) => {
  var _b = _a, {
    size = "default",
    label = "",
    active = false,
    onChange
  } = _b, rest = __objRest(_b, [
    "size",
    "label",
    "active",
    "onChange"
  ]);
  return /* @__PURE__ */ jsxs9("div", __spreadProps(__spreadValues({}, rest), { className: `select-none flex items-center`, children: [
    /* @__PURE__ */ jsx15(
      "div",
      {
        className: `p-px w-8 rounded-full border border-solid border-borderDefault cursor-pointer ${active ? "bg-uiActive" : "bg-brandWhite"}`,
        onClick: onChange,
        children: /* @__PURE__ */ jsx15(
          "div",
          {
            className: `w-3.5 h-3.5 rounded-full transform duration-300 ease-in-out ${active ? "bg-brandWhite translate-x-3.5" : "bg-greyStrong"}`
          }
        )
      }
    ),
    !!label && /* @__PURE__ */ jsx15(
      "label",
      {
        className: `cursor-pointer ml-2 whitespace-nowrap text-textcolorBlack ${size === "small" ? "text-sm" : "text-base"}`,
        onClick: onChange,
        children: label
      }
    )
  ] }));
};

// src/components/Label/index.tsx
import { jsxs as jsxs10 } from "react/jsx-runtime";
var Label = (_a) => {
  var _b = _a, {
    name,
    text,
    layout = "row",
    required = false
  } = _b, rest = __objRest(_b, [
    "name",
    "text",
    "layout",
    "required"
  ]);
  const positionLabel = {
    row: "mb-2 w-full sm:py-4 sm:pr-1 sm:w-52 sm:m-0",
    stacked: "mb-2 w-full"
  }[layout || "row"];
  return /* @__PURE__ */ jsxs10(
    "label",
    __spreadProps(__spreadValues({
      htmlFor: name,
      className: `bg-brandWhite text-textcolorBlack font-primary text-base flex  ${positionLabel}`
    }, rest), {
      children: [
        text,
        " ",
        required && "*"
      ]
    })
  );
};

// src/components/InputField/index.tsx
import { useState as useState9, useEffect as useEffect7 } from "react";
import { jsx as jsx16, jsxs as jsxs11 } from "react/jsx-runtime";
var InputField = (_a) => {
  var _b = _a, {
    name = "",
    state = "default",
    type = "text",
    placeholder,
    disabled = false,
    value = "",
    onKeyUp,
    onKeyDown,
    extraAttributes,
    updateFormDataState
  } = _b, rest = __objRest(_b, [
    "name",
    "state",
    "type",
    "placeholder",
    "disabled",
    "value",
    "onKeyUp",
    "onKeyDown",
    "extraAttributes",
    "updateFormDataState"
  ]);
  const [inputValue, setInputValue] = useState9(value);
  useEffect7(() => {
    setInputValue(value);
  }, [value]);
  const handleChange = (e) => {
    const { target } = e;
    const { value: value2 } = target;
    let cleanValue = "";
    if (value2.slice(-2) === "  ") {
      cleanValue = value2.trimLeft().slice(0, -1);
    } else if (type === "password") {
      cleanValue = value2.trimLeft();
    } else {
      cleanValue = value2.trimLeft().replace(/["#]/g, "");
    }
    setInputValue(cleanValue);
    () => updateFormDataState(name, cleanValue);
  };
  const border = {
    default: "border border-solid border-formInputBorder focus:enabled:border-formInputBorderfocus hover:enabled:border-formInputBorderhover",
    success: "border border-solid border-formInputBordersuccess",
    error: "border border-solid border-formInputBordererror"
  }[state || "default"];
  const background = disabled ? "bg-formInputBackgroundDisabled" : "bg-formInputBackground";
  return /* @__PURE__ */ jsxs11("div", { className: "relative flex justify-between min-h-full items-center w-full h-fit", children: [
    /* @__PURE__ */ jsx16(
      "input",
      __spreadValues(__spreadValues({
        id: name,
        name,
        className: `${border} ${background} text-formInputText text-base font-primary outline-none w-full placeholder-formInputTextplaceholder p-4`,
        placeholder,
        value: inputValue,
        type,
        onKeyUp,
        onKeyDown,
        onChange: handleChange,
        disabled,
        "data-test-id": `input${name ? capitalize(name) : "Text"}`
      }, extraAttributes), rest)
    ),
    /* @__PURE__ */ jsxs11("div", { className: "absolute inset-y-0 right-0 top-0 flex items-center pr-4", children: [
      state === "error" && /* @__PURE__ */ jsx16(Icon, { name: "Xmark" }),
      state === "success" && /* @__PURE__ */ jsx16(Icon, { name: "Check" })
    ] })
  ] });
};

// src/components/TextArea/index.tsx
import { useState as useState10 } from "react";
import { jsx as jsx17 } from "react/jsx-runtime";
var TextArea = (_a) => {
  var _b = _a, {
    name = "",
    state = "default",
    placeholder,
    value = "",
    rows,
    maxCharacters,
    disabled = false,
    updateFormDataState
  } = _b, rest = __objRest(_b, [
    "name",
    "state",
    "placeholder",
    "value",
    "rows",
    "maxCharacters",
    "disabled",
    "updateFormDataState"
  ]);
  const [inputValue, setInputValue] = useState10(value);
  const updateText = (e) => {
    const newValue = e.target.value;
    const specialChars = /[`@#$%^&*_+\=\[\]{};'"\\|<>\/~\n(.,:)!?àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]/;
    let validValue = "";
    if (maxCharacters && newValue.length > maxCharacters || specialChars.test(newValue)) {
      validValue = newValue.substring(0, maxCharacters).replace(specialChars, "");
    } else {
      validValue = newValue;
    }
    setInputValue(validValue);
    () => updateFormDataState(name, validValue);
  };
  const border = {
    default: "border border-solid border-formInputBorder focus:enabled:border-formInputBorderfocus hover:enabled:border-formInputBorderhover",
    success: "border border-solid border-formInputBordersuccess",
    error: "border border-solid border-formInputBordererror"
  }[state || "default"];
  const background = disabled ? "bg-formInputBackgroundDisabled" : "bg-formInputBackground";
  return /* @__PURE__ */ jsx17("div", { className: "relative flex justify-between min-h-full items-center w-full h-fit", children: /* @__PURE__ */ jsx17(
    "textarea",
    __spreadValues({
      id: name,
      name,
      className: ` ${border} ${background} text-formInputText text-base font-primary outline-none w-full placeholder-formInputTextplaceholder p-4`,
      placeholder,
      value: inputValue,
      onChange: updateText,
      disabled,
      "data-test-id": `textarea-${name ? name : "text"}`,
      rows
    }, rest)
  ) });
};

// src/components/Select/index.tsx
import { useState as useState11 } from "react";
import { jsx as jsx18, jsxs as jsxs12 } from "react/jsx-runtime";
var Select = (_a) => {
  var _b = _a, {
    name = "",
    state = "default",
    value,
    options = [],
    placeholder,
    disabled = false,
    updateFormDataState
  } = _b, rest = __objRest(_b, [
    "name",
    "state",
    "value",
    "options",
    "placeholder",
    "disabled",
    "updateFormDataState"
  ]);
  const [selectedOption, setSelectedOption] = useState11(value);
  if (placeholder) {
    if (!options.some((option) => option.value === "placeholder")) {
      options.unshift({
        value: "placeholder",
        displayName: placeholder,
        disabled: true,
        hidden: true,
        selected: true
      });
    }
  }
  const handleChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    () => updateFormDataState(name, option);
  };
  const renderOptions = options.map((option) => {
    return /* @__PURE__ */ jsx18(
      "option",
      {
        value: option.value,
        selected: option.selected,
        disabled: option.disabled,
        hidden: option.hidden,
        children: option.displayName
      },
      option.value
    );
  });
  const border = {
    default: "border border-solid border-formInputBorder focus:enabled:border-formInputBorderfocus hover:enabled:border-formInputBorderhover",
    success: "border border-solid border-formInputBordersuccess",
    error: "border border-solid border-formInputBordererror"
  }[state || "default"];
  const background = disabled ? "bg-formInputBackgroundDisabled" : "bg-transparent";
  return /* @__PURE__ */ jsxs12("div", { className: "relative w-full", children: [
    /* @__PURE__ */ jsx18(
      "select",
      __spreadProps(__spreadValues({
        name,
        id: name,
        disabled,
        className: `${border} ${background} text-formInputText text-base font-primary outline-none w-full placeholder-formInputTextplaceholder p-4 appearance-none`,
        onChange: handleChange,
        value: selectedOption
      }, rest), {
        children: renderOptions
      })
    ),
    /* @__PURE__ */ jsx18("div", { className: "absolute inset-y-0 right-0 top-0 flex items-center pr-4 -z-10", children: /* @__PURE__ */ jsx18(Icon, { name: "NavArrowDown" }) })
  ] });
};

// src/components/Checkbox/index.tsx
import { jsx as jsx19, jsxs as jsxs13 } from "react/jsx-runtime";
var Checkbox = (_a) => {
  var _b = _a, {
    name,
    displayName,
    value = false,
    disabled = false,
    updateFormDataState,
    state = "default",
    onChange
  } = _b, rest = __objRest(_b, [
    "name",
    "displayName",
    "value",
    "disabled",
    "updateFormDataState",
    "state",
    "onChange"
  ]);
  const border = {
    default: "border border-solid border-formInputBorder focus:border-formInputBorderfocus hover:border-formInputBorderhover",
    success: "border border-solid border-formInputBordersuccess",
    error: "border border-solid border-formInputBordererror"
  }[state || "default"];
  const color = !!value ? "text-formCheckboxCheckedText" : "text-transparent hover:text-formCheckboxHoverText";
  const background = disabled ? "bg-formInputBackgroundDisabled" : "bg-transparent";
  return /* @__PURE__ */ jsxs13(
    "div",
    __spreadProps(__spreadValues({}, rest), {
      className: `select-none flex items-center`,
      "data-test-id": `checkbox${capitalize(name)}`,
      onClick: onChange,
      children: [
        /* @__PURE__ */ jsx19(
          "input",
          {
            name,
            checked: value,
            type: "checkbox",
            disabled,
            className: "hidden",
            readOnly: true
          }
        ),
        /* @__PURE__ */ jsx19(
          "div",
          {
            className: `flex justify-center items-center h-5 w-5 ${border} ${color} ${background}`,
            children: /* @__PURE__ */ jsx19(Icon, { name: "Check" })
          }
        ),
        displayName && /* @__PURE__ */ jsx19("span", { className: "cursor-pointer ml-2 whitespace-nowrap text-textcolorBlack text-base", children: displayName })
      ]
    })
  );
};

// src/components/RadioButton/index.tsx
import { jsx as jsx20, jsxs as jsxs14 } from "react/jsx-runtime";
var RadioButton = (_a) => {
  var _b = _a, {
    name = "",
    displayName = "",
    checked = false,
    value = "",
    onChange,
    image
  } = _b, rest = __objRest(_b, [
    "name",
    "displayName",
    "checked",
    "value",
    "onChange",
    "image"
  ]);
  const classessChecked = checked ? "after:absolute after:block after:bg-formRadioActive after:w-3 after:h-3 after:left-1 after:top-2 after:rounded-full" : "";
  return /* @__PURE__ */ jsxs14(
    "label",
    __spreadProps(__spreadValues({
      className: `flex relative cursor-pointer before:absolute before:w-5 before:h-5 before:left-0 pl-10 mr-5`,
      "data-test-id": `radio${capitalize(displayName)}`
    }, rest), {
      children: [
        /* @__PURE__ */ jsx20(
          "input",
          {
            name,
            className: "absolute invisible",
            type: "radio",
            checked,
            value,
            onChange
          }
        ),
        /* @__PURE__ */ jsxs14(
          "span",
          {
            className: `flex before:absolute before:inline-block before:w-5 before:h-5 before:left-0 before:top-1 before:border before:border-solid before:border-formInputBorder before:rounded-full before:color-formRadioActive ${classessChecked}`,
            children: [
              !!image && /* @__PURE__ */ jsx20("div", { className: "w-7 mr-2", children: /* @__PURE__ */ jsx20("img", { src: image, height: "25", alt: displayName }) }),
              displayName
            ]
          }
        )
      ]
    })
  );
};

// src/components/InputBlock/index.tsx
import { jsx as jsx21, jsxs as jsxs15 } from "react/jsx-runtime";
var InputBlock = (_a) => {
  var _b = _a, {
    name,
    placeholder,
    value,
    state = "default",
    type,
    layout = "stacked",
    label = "",
    hint = "",
    htmlAttributes,
    updateFormDataState
  } = _b, rest = __objRest(_b, [
    "name",
    "placeholder",
    "value",
    "state",
    "type",
    "layout",
    "label",
    "hint",
    "htmlAttributes",
    "updateFormDataState"
  ]);
  return /* @__PURE__ */ jsxs15(
    "div",
    __spreadProps(__spreadValues({
      className: `flex ${layout === "row" ? "flex-row" : "flex-col"}`,
      "data-test-id": `block${capitalize(type)}${capitalize(name)}`
    }, rest), {
      children: [
        label && /* @__PURE__ */ jsx21(
          Label,
          {
            name,
            text: label,
            layout,
            required: htmlAttributes == null ? void 0 : htmlAttributes.required
          }
        ),
        /* @__PURE__ */ jsxs15("div", { className: "w-full h-fit", children: [
          /* @__PURE__ */ jsxs15("div", { className: "relative flex justify-between min-h-full items-center w-full h-fit", children: [
            type === "inputfield" && /* @__PURE__ */ jsx21(
              InputField,
              {
                type: htmlAttributes == null ? void 0 : htmlAttributes.type,
                name,
                placeholder,
                state,
                disabled: htmlAttributes == null ? void 0 : htmlAttributes.disabled,
                value,
                updateFormDataState,
                extraAttributes: htmlAttributes == null ? void 0 : htmlAttributes.extraAttributes
              }
            ),
            type === "textArea" && /* @__PURE__ */ jsx21(
              TextArea,
              {
                name,
                placeholder,
                state,
                value,
                disabled: htmlAttributes == null ? void 0 : htmlAttributes.disabled,
                rows: htmlAttributes == null ? void 0 : htmlAttributes.rows,
                updateFormDataState
              }
            ),
            type === "select" && /* @__PURE__ */ jsx21(
              Select,
              {
                name,
                placeholder,
                state,
                value,
                disabled: htmlAttributes == null ? void 0 : htmlAttributes.disabled,
                options: htmlAttributes == null ? void 0 : htmlAttributes.options,
                updateFormDataState
              }
            ),
            type === "checkbox" && /* @__PURE__ */ jsx21("div", { className: layout === "row" ? "pt-4" : "", children: /* @__PURE__ */ jsx21(
              Checkbox,
              {
                name,
                value,
                state,
                disabled: htmlAttributes == null ? void 0 : htmlAttributes.disabled,
                updateFormDataState
              }
            ) }),
            type === "radio" && /* @__PURE__ */ jsx21("div", { className: `flex ${layout === "row" ? "pt-4" : ""}`, children: htmlAttributes == null ? void 0 : htmlAttributes.options.map((option) => {
              return /* @__PURE__ */ jsx21(
                RadioButton,
                {
                  name,
                  displayName: option.displayName,
                  checked: value === option.value,
                  value: option.value,
                  onChange: () => updateFormDataState(name, option.value)
                },
                option.value
              );
            }) })
          ] }),
          hint && /* @__PURE__ */ jsx21(
            "div",
            {
              className: "mt-1 text-formInputTextcolor font-primary text-sm",
              "data-test-id": `hint${capitalize(name)}`,
              children: hint
            }
          )
        ] })
      ]
    })
  );
};

// src/components/Optionbox/index.tsx
import { useState as useState13 } from "react";

// src/components/Modal/index.tsx
import { useState as useState12 } from "react";
import {
  useFloating as useFloating2,
  useDismiss as useDismiss2,
  useRole as useRole2,
  useClick as useClick2,
  useInteractions as useInteractions2,
  useId,
  FloatingFocusManager,
  FloatingOverlay
} from "@floating-ui/react";
import { Fragment, jsx as jsx22, jsxs as jsxs16 } from "react/jsx-runtime";
var Modal = (_a) => {
  var _b = _a, { title, content, children } = _b, rest = __objRest(_b, ["title", "content", "children"]);
  const [isOpen, setIsOpen] = useState12(false);
  const { refs, context } = useFloating2({
    open: isOpen,
    onOpenChange: setIsOpen
  });
  const click = useClick2(context);
  const dismiss = useDismiss2(context, {
    outsidePressEvent: "mousedown"
  });
  const role = useRole2(context);
  const { getReferenceProps, getFloatingProps } = useInteractions2([
    click,
    dismiss,
    role
  ]);
  const labelId = useId();
  const descriptionId = useId();
  return /* @__PURE__ */ jsxs16(Fragment, { children: [
    /* @__PURE__ */ jsx22("div", __spreadProps(__spreadValues({ ref: refs.setReference }, getReferenceProps()), { children })),
    isOpen && /* @__PURE__ */ jsx22(
      FloatingOverlay,
      {
        lockScroll: true,
        className: "flex items-center justify-center bg-black bg-opacity-75",
        children: /* @__PURE__ */ jsx22(FloatingFocusManager, { context, children: /* @__PURE__ */ jsxs16(
          "div",
          __spreadProps(__spreadValues(__spreadValues({
            className: "bg-brandWhite min-w-80 w-full sm:max-w-2xl h-full sm:h-auto p-7",
            ref: refs.setFloating,
            "aria-labelledby": labelId,
            "aria-describedby": descriptionId
          }, getFloatingProps()), rest), {
            children: [
              title && /* @__PURE__ */ jsxs16("div", { className: "flex justify-between items-center pb-2 mb-7 border-b border-solid border-borderDefault", children: [
                /* @__PURE__ */ jsx22(
                  "h2",
                  {
                    id: labelId,
                    className: "text-base sm:text-2xl font-weight-primary-bold font-primary text-textcolorBlack",
                    children: title
                  }
                ),
                /* @__PURE__ */ jsx22(
                  "div",
                  {
                    onClick: () => setIsOpen(false),
                    className: "cursor-pointer",
                    children: /* @__PURE__ */ jsx22(Icon, { name: "Xmark", size: "md" })
                  }
                )
              ] }),
              content
            ]
          })
        ) })
      }
    )
  ] });
};

// src/components/Optionbox/index.tsx
import { jsx as jsx23, jsxs as jsxs17 } from "react/jsx-runtime";
var Optionbox = (_a) => {
  var _b = _a, {
    label,
    isActive = false,
    isEditable = false,
    onClick,
    tooltipContent,
    modalTitle,
    submitForm,
    labelCustomerOrderCheckbox = "",
    labelCustomerName = "",
    labelCustomerEmail = "",
    labelCustomerPhone = "",
    labelCustomerOrderPrint = "",
    labelCustomerRemark = "",
    labelReference = "",
    submitButtonText = ""
  } = _b, rest = __objRest(_b, [
    "label",
    "isActive",
    "isEditable",
    "onClick",
    "tooltipContent",
    "modalTitle",
    "submitForm",
    "labelCustomerOrderCheckbox",
    "labelCustomerName",
    "labelCustomerEmail",
    "labelCustomerPhone",
    "labelCustomerOrderPrint",
    "labelCustomerRemark",
    "labelReference",
    "submitButtonText"
  ]);
  const [customerOrderActive, setCustomerOrderActive] = useState13(false);
  const [customerOrderPrint, setCustomerOrderPrint] = useState13(false);
  const [customerName, setCustomerName] = useState13("");
  const toggleCustomerOrder = () => {
    setCustomerOrderActive(!customerOrderActive);
  };
  const toggleCustomerOrderPrint = () => {
    setCustomerOrderPrint(!customerOrderPrint);
  };
  const getCostumerName = (e) => {
    setCustomerName(e.target.value);
  };
  const renderContent = () => {
    return /* @__PURE__ */ jsxs17("form", { action: submitForm, children: [
      /* @__PURE__ */ jsx23("div", { className: "p-4 bg-greyPaler mb-7", children: /* @__PURE__ */ jsx23(
        Checkbox,
        {
          name: "customerOrder",
          label: labelCustomerOrderCheckbox,
          onClick: toggleCustomerOrder,
          checked: customerOrderActive
        }
      ) }),
      customerOrderActive && /* @__PURE__ */ jsxs17("div", { className: "duration-500 ease-out transition-all", children: [
        /* @__PURE__ */ jsx23("div", { className: "mb-7", children: /* @__PURE__ */ jsx23(
          InputBlock,
          {
            name: "customerName",
            label: labelCustomerName,
            layout: "row",
            onKeyUp: getCostumerName
          }
        ) }),
        /* @__PURE__ */ jsx23("div", { className: "mb-7", children: /* @__PURE__ */ jsx23(
          InputBlock,
          {
            name: "customerEmail",
            type: "email",
            label: labelCustomerEmail,
            layout: "row"
          }
        ) }),
        /* @__PURE__ */ jsx23("div", { className: "mb-7", children: /* @__PURE__ */ jsx23(
          InputBlock,
          {
            name: "customerPhone",
            type: "number",
            label: labelCustomerPhone,
            layout: "row",
            pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}"
          }
        ) }),
        /* @__PURE__ */ jsx23("div", { className: "mb-7", children: /* @__PURE__ */ jsx23(
          TextArea,
          {
            name: "remarks",
            label: labelCustomerRemark,
            layout: "row"
          }
        ) }),
        /* @__PURE__ */ jsx23("div", { className: "p-4 bg-greyPaler mb-7", children: /* @__PURE__ */ jsx23(
          Checkbox,
          {
            name: "customerOrder",
            label: labelCustomerOrderPrint,
            onClick: toggleCustomerOrderPrint,
            checked: customerOrderPrint
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx23("div", { className: "mb-7", children: /* @__PURE__ */ jsx23(
        InputBlock,
        {
          name: "customerName",
          label: labelReference,
          layout: "stacked",
          state: customerOrderActive ? "disabled" : "default",
          value: customerOrderActive ? `Kb: ${customerName}` : ""
        }
      ) }),
      /* @__PURE__ */ jsx23(
        Button,
        {
          variant: "primary",
          size: "large",
          iconRight: /* @__PURE__ */ jsx23(Icon, { name: "ShoppingBag", size: "sm" }),
          fullWidth: true,
          onClick: submitForm,
          children: submitButtonText
        }
      )
    ] });
  };
  return /* @__PURE__ */ jsxs17(
    "div",
    __spreadProps(__spreadValues({}, rest), {
      className: "group flex justify-between rounded-full border border-solid border-borderDefault hover:border-borderStrong text-borderDefault hover:text-borderStrong p-2 bg-brandWhite",
      children: [
        /* @__PURE__ */ jsxs17(
          "div",
          {
            onClick,
            className: "flex flex-1 gap-2 items-center cursor-pointer",
            children: [
              !!isActive ? /* @__PURE__ */ jsx23("span", { className: "text-uiSuccessStrong", children: /* @__PURE__ */ jsx23(Icon, { name: "CheckCircleSolid", size: "sm" }) }) : /* @__PURE__ */ jsx23(Icon, { name: "Circle", size: "sm" }),
              isEditable ? /* @__PURE__ */ jsx23(Modal, { title: modalTitle, content: renderContent(), children: /* @__PURE__ */ jsx23("span", { className: "text-textcolorGrey leading-none w-full", children: label }) }) : /* @__PURE__ */ jsx23("span", { className: "text-textcolorGrey leading-none", children: label })
            ]
          }
        ),
        tooltipContent && /* @__PURE__ */ jsx23("div", { className: "hidden group-hover:block", children: /* @__PURE__ */ jsx23(Tooltip, { content: tooltipContent, trigger: "click", children: /* @__PURE__ */ jsx23(Icon, { name: "InfoCircle", size: "sm" }) }) })
      ]
    })
  );
};
export {
  Annotation,
  Badge,
  Button,
  Checkbox,
  CopyText,
  Excerpt,
  Icon,
  InputBlock,
  InputField,
  Label,
  Metabar,
  Modal,
  Optionbox,
  ProductImage,
  PromoNotification,
  QuantityInput,
  RadioButton,
  Rating,
  Select,
  Switch,
  TextArea,
  TitleAndSubtitle,
  Tooltip
};
